<template>
  <transition name="fade" mode="out-in">
    <div class="privacy-page-container">
      <nav-component></nav-component>
      <div class="privacy-hero">
        <h1>PRIVACY POLICY</h1>
      </div>
      <colours-bar></colours-bar>
      <div class="body-container">
        <h1>Effective Date</h1>

        <p>This Privacy Notice is effective July 26, 2022. It was last updated July 26, 2022.</p>

        <h1>Statement on Privacy</h1>

        <p>Our organization takes great care to respect the privacy of the personal and corporate data contained within its systems. For this reason, we collect and process data only for reasons and ways needed to provide our services to you. Your personal data includes information such as:</p>

        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Mailing address</li>
          <li>Phone number</li>
          <li>Other data collected that could directly or indirectly identify you</li>
        </ul>

        <p>Our Privacy Notice is intended to describe to you how and what data we collect, and how and why we use your personal data. It also describes options we provide for you to access, update or otherwise take control of your personal data that we process.</p>

        <h1>Privacy Contact</h1>

        <p>If you have questions about our policies or processes described below, you may contact our privacy team by emailing <a href="mailto:privacy@carltonone.com" style="color: inherit; text-decoration: underline;">privacy@carltonone.com</a> or <a href="mailto:privacy@globalrewardsolutions.com" style="color: inherit; text-decoration: underline;">privacy@globalrewardsolutions.com</a>. These imboxes are actively monitored and someone will contact you quickly to answer your questions.</p>

        <h1>Data Collection</h1>

        <p>We collect information so that we are able to deliver the best possible user experience on our platform(s). Much of this information is collected in the following ways:</p>

        <ul>
          <li>Website Contact Form - as done by you when requesting a call-back, white-paper, or subscription to a newsletter</li>
          <li>Account Creation or Modification - as done by you directly or by an organization that has enrolled you on our platform(s)</li>
          <li>Order Placement - when placing an order in our system that requires a delivery address or contact details</li>
          <li>Customer Service Inquiry - when contacting our support team to address an issue</li>
        </ul>

        <p>The information collected is structured to be at the bare minimum required to provide adequate services to our clients and users. Additionally, we do have other forms of data collection that may not be obvious:</p>

        <p><strong>Cookies</strong></p>

        <p>These allow us to track browsing behavior on our platforms. We make use of Google Analytics to provide us insight into site usage and overall performance from the end user's perspective. No personal data is transferred to Google in this way and we do not make use of tracking beyond our platform(s); all cookies are first-party cookies.<br>We additionally make use of cookies to manage user authentication against our platforms. These cookies are essential for the proper operation of the application.</p>

        <p><strong>Service Usage</strong></p>

        <p>Usage logs are automatically generated through all interactions with our services. These logs contain specific service usage, source IP addresses, site referral URLs, system performance, as well as browser, operating system, and device information.</p>

        <p><strong>Supplemental Data</strong></p>

        <p>Upon enrollment, your account may have been configured with additional details regarding your account and role within the enrolled program. This data is typically provided by the enrolling organization and typically consists of a job title and department. The information provided is typically pertinent to the type of program your organization is operating on our platform. Inquiries regarding this type of data should be forwarded to your own organization.</p>

        <h1>Data Usage</h1>

        <p>While we collect a minimum amount of data on our users, we strictly process this data solely for the purposes of providing contracted services to the end user and client. Data is only used for purposes that:</p>

        <ol>
          <li>for which we have been given permission</li>
          <li>are necessary to deliver the Services you purchase or interact with, or</li>
          <li>might be required or permitted for legal compliance or other lawful purposes</li>
        </ol>

        <p>These uses include delivering, improving, updating, and enhancing the Services we provide to you. We collect various types of information relating to your use and/or interactions with our Services.</p>

        <p>We use this information to:</p>

        <ul>
          <li><strong>Improve and optimize the operation and performance of our Services</strong>, specifically our applications and customer service.</li>
          <li><strong>Diagnose problems</strong> with and identify any security risks, errors or needed enhancements to the Services.</li>
          <li><strong>Detect and prevent fraud</strong> and abuse of our Services and systems.</li>
          <li><strong>Collect aggregate statistics</strong> about the use of the Services.</li>
          <li><strong>Understand and analyze how you use our Services</strong> and what features and products are most relevant to you.</li>
        </ul>

        <p>Often, much of the data collected is aggregated or statistical data about how individuals use our Services, and is not linked to any personal data, but to the extent it is itself personal data, or is linked or linkable to personal data, we treat it accordingly.</p>

        <h1>Data Sharing</h1>

        <p>We may share your information with the following entities in order to provide you with service:</p>

        <ol>
          <li><strong>Outsourced Call Center</strong> - we may make use of an outsourced call center with contracted dedicated staff to provide 24/7 customer service to our clients & users.</li>
          <li><strong>Product Fulfillment</strong> - in order to provide local fulfillment of reward orders we will provide your shipping details to a local supplier who will deliver goods to you.</li>
        </ol>

        <p>In all cases, we ensure that our sub-processing partners provide the same level of security and privacy to your personal data that we provide.</p>

        <h1>Data Rights</h1>

        <p><strong>Data Access</strong></p>

        <p>You are permitted to request a report of the personal data contained in our platforms. To request a report, you may do any of the following:</p>

        <ol>
          <li>Use the provided tools on the platform (if available) and generate the report on an as needed basis.</li>
          <li>Contact your program administrator</li>
          <li>Contact our customer service team directly and submit the request by way of phone or email (contact details should be provided within your program)</li>
          <li>Contact our privacy team at <a href="mailto:privacy@carltonone.com" style="color: inherit; text-decoration: underline;">privacy@carltonone.com</a> or <a href="mailto:privacy@globalrewardsolutions.com" style="color: inherit; text-decoration: underline;">privacy@globalrewardsolutions.com</a></li>
        </ol>

        <p><strong>Data Editing</strong></p>

        <p>You are permitted to update your user profile information at any time using the provided tools on our platform(s). If you are unable to do so, please contact your local program administrator.</p>

        <p><strong>Data Deletion</strong></p>

        <p>You are permitted to request to delete your personal information from our platform. After doing so, we will be unable to provide any additional service to you as a customer. This will have the following consequences:</p>
        <ol>
          <li>
            We will be unable to provide you with customer support regarding any placed orders:
            <ul>
              <li>a. Unable to handle any order status / delivery updates</li>
              <li>b. Unable to handle any product returns</li>
              <li>c. Unable to provide any refunds</li>
              <li>d. Unable to provide any warranty proof-of-purchase</li>
            </ul>
          </li>
          <li>We will be unable to provide you with any historical account of your activities in the system.</li>
          <li>We will be unable to reactivate your account and/or reissue any terminated account balances</li>
        </ol>
        
        <p>Similarly to personal data reports, you may request to have your personal data removed from our platform(s) in any of the following ways:</p>

        <ol>
          <li>Use the provided tools on the platform (if available) and trigger a deletion request</li>
          <li>Contact your program administrator</li>
          <li>Contact our customer service team directly and submit the request by way of phone or email (contact details should be provided within your program)</li>
          <li>Contact our privacy team at <a href="mailto:privacy@carltonone.com" style="color: inherit; text-decoration: underline;">privacy@carltonone.com</a> or <a href="mailto:privacy@globalrewardsolutions.com" style="color: inherit; text-decoration: underline;">privacy@globalrewardsolutions.com</a></li>
        </ol>

        <h1>Data Handling</h1>

        <p><strong>Data Security</strong></p>

        <p>We follow generally accepted standards to store and protect the personal data we collect, both during transmission and once received and stored, including the utilization of encryption where appropriate.</p>

        <p><strong>Data Storage</strong></p>
        <p>Data is stored securely at multiple locations, in conjunction with our technology partners.</p>
        <ul style="list-style-type: none;">
          <li style="margin-bottom: 20px;">
            <strong>Primary Data Facility</strong><br>
            Technology Partner: <a href="https://www.estruxture.com/" target="_blank">eStruxture Data Centers</a> (formerly Aptum Technologies)<br>
            Location: Toronto, Canada<br>
            Services: Colocation
          </li>
          <li>
            <strong>Public Cloud</strong><br>
            Technology Partner: <a href="https://aws.amazon.com/" target="_blank">AWS Canada</a><br>
            Region: ca-central-1<br>
            Services: Compute, Storage, DNS, DRaaS
          </li>
        </ul>

        <p><strong>Data Retention</strong></p>

        <p>We retain personal data only for as long as necessary to provide services and thereafter for a variety of legitimate legal or business purposes. These might include retention periods:</p>

        <ul>
          <li>Mandated by law, contract or similar obligations applicable to our business operations.</li>
          <li>For preserving, resolving, defending or enforcing our legal/contractual rights.</li>
          <li>Needed to maintain adequate and accurate business and financial records.</li>
        </ul>

        <p>If you have any questions about the security or retention of your personal data, you can contact us at <a href="mailto:privacy@carltonone.com" style="color: inherit; text-decoration: underline;">privacy@carltonone.com</a> or <a href="mailto:privacy@globalrewardsolutions.com" style="color: inherit; text-decoration: underline;">privacy@globalrewardsolutions.com</a>.</p>

        <p><strong>International Transfers of Collected Information</strong></p>

        <p>If you use our Services from a country other than the country where our servers are located, your communications with us may result in transferring your personal data across international borders. Also, when you call us or initiate a chat, we may provide you with support from one of our global locations outside your country of origin. In these cases, your personal data is handled according to this Privacy Notice.</p>

        <p><strong>International Transfers to Third Parties</strong></p>

        <p> in other countries that may not have equivalent privacy and data protection laws to the country in which you reside. When we share information of customers in the European Economic Area, the UK, or Switzerland, we will make use of the following:</p>

        <ul>
          <li>Standard contractual data protection clauses</li>
          <li>Binding corporate rules for transfers to data processors</li>
          <li>Legal and security mechanisms to safeguard the transfer</li>
        </ul>

        <p><strong>Compliance with Legal, Regulatory, and Law Enforcement Requests</strong></p>

        <p>We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. We will disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (such as subpoena requests), to protect our property and rights or the property and rights of a third party, to protect the safety of the public or any person, or to prevent or stop activity we consider to be illegal or unethical.<br>To the extent we are legally permitted to do so, we will take reasonable steps to notify you in the event that we are required to provide your personal information to third parties as part of legal process.</p>

        <p><strong>'Do Not Track' Notifications</strong></p>

        <p>Some browsers allow you to automatically notify websites you visit not to track you using a “Do Not Track” signal. There is no consensus among industry participants as to what “Do Not Track” means in this context. Like many websites and online services, we currently do not alter our practices when we receive a “Do Not Track” signal from a visitor’s browser. To find out more about “Do Not Track,” you may visit <a href="www.allaboutdnt.com" style="color: inherit; text-decoration: underline;">www.allaboutdnt.com</a>.</p>

        <p><strong>Age Restrictions</strong></p>

        <p>Our Services are available for purchase only for those over the age of 16. Our Services are not targeted to, intended to be consumed by or designed to entice individuals under the age of 16. If you know of or have reason to believe anyone under the age of 16 has provided us with any personal data, please contact us at <a href="mailto:privacy@carltonone.com" style="color: inherit; text-decoration: underline;">privacy@carltonone.com</a> or <a href="mailto:privacy@globalrewardsolutions.com" style="color: inherit; text-decoration: underline;">privacy@globalrewardsolutions.com</a>.</p>

        <h1>List of Sub-Processors</h1>

        <div class="scrollable-table-container">
          <table class="sub-processors">
            <tr class="header">
              <td><strong>Sub-Processor</strong></td>
              <td class="border-left-right"><strong>Description/Purpose</strong></td>
              <td><strong>Location</strong></td>
            </tr>
            <tr class="light-blue">
              <td><strong>AWS Canada</strong></td>
              <td class="border-left-right">Hybrid-cloud services (compute and storage); storage of replicated backups; on-premises DR landing zone; DNS services</td>
              <td>Region: ca-central-1</td>
            </tr>
            <tr>
              <td><strong>eStruxture Data Centers</strong></td>
              <td class="border-left-right">Colocation; houses our production environment and primary data center</td>
              <td>Toronto, Canada</td>
            </tr>
            <tr class="light-blue">
              <td><strong>Freshworks</strong></td>
              <td class="border-left-right">Customer service ticketing solution. System manages the inbound/outbound communication between CarltonOne and the end-user help requests</td>
              <td>California, USA (AWS Region: us-east-1)</td>
            </tr>
            <tr>
              <td><strong>HubSpot</strong></td>
              <td class="border-left-right">Customer Relationship Management system. System manages the client-service related communications between CarltonOne and clients</td>
              <td>Massachusetts, USA (AWS Region: us- east-1)</td>
            </tr>
            <tr class="light-blue">
              <td><strong>MicroSourcing</strong></td>
              <td class="border-left-right">Outsourced customer call center. Order Processing Coordinators and Customer Service Representatives may have access to limited customer data in order to provide contracted end-user support services</td>
              <td>Manila, Philippines</td>
            </tr>
          </table>
        </div>

        

        <h1>Privacy Policy Changes</h1>

        <p>We reserve the right to modify this Privacy Notice at any time. If we decide to change our Privacy Notice, we will post those changes to this documentation and any other places we deem appropriate, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.</p>
        
        <p>This Privacy Notice was last updated July 26, 2022.</p>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>
  export default {
    data: function() {
      return {
        name: 'privacy-policy'
      }
    }
  }
</script>

<style>
  /*HEADER/HERO*/
  .privacy-page-container { background-color: #f1f2f2; }
  .privacy-hero {
    background-image: url(../assets/img/privacy-hero.jpg);
    background-position: center;
    -webkit-background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .privacy-hero h1 {
    color: #fff;
    padding-top: 35px;
    font-size: 26px;
    letter-spacing: 6px;
    font-weight: 300;
  }

  .body-container {
    /*background-color: #fff;*/
    width: 80%;
    max-width: 1200px;
    margin: 60px auto;
  }

  .body-container * { color: #636466; }
  .body-container h1 {
    font-weight: 300;
    color: #636466;
    margin-bottom: 20px;
    font-size: 46px;
  }

  .scrollable-table-container {
    width: 100%;
    overflow-y: auto;
  }

  table.sub-processors {
    width: 100%;
    margin-bottom: 40px;
    border-collapse: collapse;
  }
  table.sub-processors tr.header { background-color: #00b1ff; }
  table.sub-processors tr.header td strong { color: #fff !important; }
  table.sub-processors tr.light-blue { background-color: rgba( 0, 177, 255, 0.2); }
  table.sub-processors td {
    padding: 8px;
    width: 33%;
  }
  table.sub-processors td.border-left-right {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    width: 35%;
  }


</style>